body 
    margin 0
    padding 0

#container 
    background #fff

.wrap 
    margin-left auto
    margin-right auto
    padding 0
    width 1000px


/*Header*/
#header 
    margin 0
    padding 25px 0

#header h1 
    margin 0
    padding 0
    width 380px
    height 167px
    float left
    background url("../images/mess-logo.png") no-repeat left center

.replace span, a#btn-catalog span, a#btn-parts span, a.btn-buy span, a.btn-add span, a.btn-quote span, a.btn-contact span, a.btn-question span, a.btn-dealer span 
    display none

#head-right 
    margin 0
    padding 0
    width 480px
    float right

#header ul 
    margin 0
    padding 8px 0 0 0
    float left
    text-align right
    list-style none

#search 
    margin 0
    padding 0
    float left
    text-align right
    list-style none

#header li 
    margin 0
    padding 0 6px 0 0
    font-family Verdana, Helvetica, sans-serif
    font-size 80%
    display inline
    font-weight bold
    text-transform uppercase
    color #3b3b3b

#header li a 
    color #3b3b3b
    text-decoration none

#header li a:hover 
    color #e3b639
    text-decoration none

#contact-info
    float right
    margin 27px 30px 0 0
    display inline-block


#contact-info ul
    list-styledisc


#contact-info ul li
    text-align right
    font-size 170%
    display block
    text-transform none
    line-height 1.5

/*Navigation*/
#navigation 
    margin 0
    padding 0

#top-tabs, #nav-1, #nav-2 
    margin 0
    padding 0

ul#main-tab 
    margin 0
    padding 0 0 0 15px

ul#main-tab li a 
    margin 0
    padding 6px 10px
    font-family Arial, Helvetica, sans-serif
    font-size 125%
    color #fff
    text-decoration none

ul#main-tab li.ui-tabs-selected a 
    margin 0
    padding 6px 10px
    font-family Arial, Helvetica, sans-serif
    font-size 125%
    color #383838
    text-decoration none
    font-weight bold

#nav-1 ul, #nav-2 ul  
    margin 0
    padding 0 0 0 10px
    background-color #e3b639
    background-image url("/images/nav-bg.gif")
    background-position left bottom
    background-repeat repeat-x
    list-style-type none

#nav-1 ul li.menu, #nav-2 ul li.menu 
    margin 0
    padding 0
    display block
    float left

#nav-1 .main a, #nav-2 .main a 
    margin 0
    padding 11px 10px
    display block
    font-family Arial, Helvetica, sans-serif
    font-size 125%
    color #383838
    text-decoration none
    font-weight bold

#nav-1 .main a:hover, #nav-2 .main a:hover 
    color #fff
    background #e3b639

#navigation .drop1 
    display none

#navigation li.menu .drop1 
    margin 0
    padding 10px
    position absolute
    background #3b3b3b
    z-index 100

#navigation li.menu .drop1 
    width 250px

#navigation li.hover .drop1 
    display block



.left-notch 
    position absolute
    top -1px
    left 0px
    width 10px
    height 20px
    background-image url("../images/left-notch.png")
    background-repeat no-repeat
    


.right-notch 
    position absolute
    top -1px
    right 0px
    width 10px
    height 20px
    background-image url("../images/right-notch.png")
    background-repeat no-repeat



.left-notch-big 
    position absolute
    top 31px
    left 0px
    width 15px
    height 20px
    background-image url("../images/left-notch-big.png")
    background-repeat no-repeat



.right-notch-big 
    position absolute
    top 31px
    right 0px
    width 15px
    height 20px
    background-image url("../images/right-notch-big.png")
    background-repeat no-repeat



dl.nav-col 
    margin 0
    padding 0 10px 0 0
    width 200px
    float left

dl.nav-col dt 
    margin 0
    padding 0
    font-family Arial, Helvetica, sans-serif
    font-size 110%
    color #fff

dl.nav-col dd 
    margin 0
    padding 0 0 3px 0
    font-family Arial, Helvetica, sans-serif
    font-size 90%
    color #fff

dl.nav-col a 
    color #fff
    text-decoration none

dl.nav-col a:hover 
    color #fff
    text-decoration underline


/*Billboard*/
.billboard 
    margin-left auto
    margin-right auto
    margin-bottom 15px
    padding 0
    width 970px
    height 410px
    overflow hidden

ul#rotate, ul#rotate2 
    margin 0
    padding 0
    list-style none
    position relative
    z-index 2

#side 
    margin 0
    padding 0
    width 300px
    position relative
    top -400px
    left 10px
    z-index 10

.feature 
    margin 0
    padding 15px
    width 270px
    height 140px
    background url("../images/feature-bg.png") no-repeat left top

.feature-retail img 
    margin 0
    padding 0 0 10px 0

.feature-retail 
    margin 0
    padding 15px
    width 270px
    height 310px
    background url("../images/feature-bg-long.png") no-repeat left top

.feature h3, .feature-retail h3 
    margin 0
    padding 0 0 8px 0
    font-family Arial, Helvetica, sans-serif
    font-size 100%
    color #3b3b3b

.feature p, .feature-retail p 
    margin 0
    padding 0 0 8px 0
    font-family Arial, Helvetica, sans-serif
    font-size 90%
    line-height 130%
    color #3b3b3b

#btn-catalog, #btn-parts 
    margin 5px
    padding 0
    display block
    width 140px
    height 26px
    float left

.btn-buy, .btn-contact, .btn-question 
    margin 0
    padding 0
    display block
    width 140px
    height 26px

.btn-add, .btn-quote, .btn-dealer 
    margin 0
    padding 3px 0
    display block
    width 192px
    height 26px

#btn-catalog 
    background url("../images/btn-catalog.png") no-repeat left top

#btn-parts 
    background url("../images/btn-parts.png") no-repeat left top

.btn-buy 
    background url("../images/btn-buy.png") no-repeat left top

.btn-add 
    background url("../images/btn-add.png") no-repeat left top

.btn-quote 
    background url("../images/btn-quote.png") no-repeat left top

.btn-dealer 
    background url("../images/btn-dealer.png") no-repeat left top

.btn-contact 
    background url("../images/btn-contact.png") no-repeat left top

.btn-question 
    background url("../images/btn-question.png") no-repeat left top


#feature-copy 
    margin 0
    padding 0

#feature-copy h2 
    margin 0
    padding 3px 0 3px 30px
    font-family Arial, Helvetica, sans-serif
    font-size 120%
    line-height 130%
    color #ecc256
    background url("../images/arrow-icon-gold.gif") no-repeat left top

#feature-copy p 
    margin 0
    padding 0 0 5px 30px
    font-family Arial, Helvetica, sans-serif
    font-size 90%
    line-height 130%
    color #fff

dl.category dt 
    margin 0
    padding 0

dl.category dd 
    margin 0
    padding 0
    font-family Arial, Helvetica, sans-serif
    font-size 85%
    text-align center
    color #fff

dl.category dd a 
    color $hanson-blue
    text-decoration none
    font-size 18px
    line-height 24px
    text-align center
    

dl.category dd a:hover 
    color $hanson-dark-grey
    text-decoration underline


/*Index*/
#index 
    margin 0
    padding 25px 0

#index-left 
    margin 0
    padding 0
    float left
    width 300px

#index-right 
    margin 0
    padding 0
    float right
    width 680px

#index-left h3, #index-right h3 
    margin 0
    padding 0 0 10px 30px
    font-family Arial, Helvetica, sans-serif
    font-size 120%
    line-height 130%
    color #3b3b3b
    background url("../images/arrow-icon-gray.gif") no-repeat left top

#index-left h3 a, #index-right h3 a 
    color #3b3b3b
    text-decoration none

.cat-desctop 
    margin 0
    padding 0 0 10px 0

/*Category Layout*/

#help-info ul 
    margin 0
    padding 0 0 10px 25px

.help-section 
    margin 0
    padding 0 0 10px 0

#prod-sidebar h6, #help-info h6, #gen-sidebar h6, #cat-desc h4 
    margin 0
    padding 3px 0
    font-family Arial, Helvetica, sans-serif
    font-size 90%
    line-height 130%
    color #555

dl.filter 
    margin 0
    padding 0 0 5px 0

dl.filter dd img 
    margin 0
    padding 0 5px 0 0

.section-head h2 
    margin 0
    padding 0 0 5px 0
    font-family Arial, Helvetica, sans-serif
    font-size 170%
    line-height 130%
    color #555

.tools 
    margin 0
    padding 0

#sort 
    margin 0
    padding 0 0 15px 0
    text-align right

#sort_form label 
    margin 0
    padding 0
    font-family Arial, Helvetica, sans-serif
    font-size 90%
    color #555
    font-weight bold
    text-align left

#sort_form input 
    font-size 90%

.filter-category-img 
    margin 0
    padding 0 0 5px 0
    text-align center
    height 120px
    overflow hidden

/*staff*/
dl.staff 
    margin 0
    padding 0 5px 10px 0
    width 175px
    float left

dl.staff img 
    margin 0 10px 10px 0 
    float left

dl.staff dt 
    margin 0
    padding 0
    font-family Arial, Helvetica, sans-serif
    font-size 100%
    line-height 130%
    color #555

dl.staff dd 
    margin 0
    padding 0
    font-family Arial, Helvetica, sans-serif
    font-size 85%
    line-height 130%
    color #555
    width 165px


/*Product Page Layout*/

#product-left 
    margin-top 20px


#certs 
    margin 0
    padding 10px 0

#price-right 
    margin 0
    padding 0
    float left

#price-left 
    margin 0
    padding 5px 0
    font-family Arial, Helvetica, sans-serif
    font-size 90%
    color #555

.imgleft 
    margin 0
    padding 0 10px 10px 0
    float left

#related 
    margin 5px 0 0 0
    padding 0

dl.related 
    margin 0
    padding 5px
    border-left 1px solid #dedede
    border-right 1px solid #dedede
    border-bottom 1px solid #dedede

dl.related img 
    margin 0
    padding 0 5px 0 0
    float left

dl.related dt 
    margin 0
    padding 0
    font-family Arial, Helvetica, sans-serif
    font-size 85%
    font-weight bold
    line-height 130%
    color #3b3b3b

dl.related dd 
    margin 0
    padding 0
    font-family Arial, Helvetica, sans-serif
    font-size 85%
    line-height 130%
    color #3b3b3b

dl.related dd a, dl.related dt a 
    color #999
    text-decoration none

dl.related dd a:hover, dl.related dt a:hover 
    color #999
    text-decoration underline

.manuals 
    margin 0
    padding 0

.manuals td 
    margin 0
    padding 5px
    font-family Arial, Helvetica, sans-serif
    font-size 85%
    line-height 130%
    color #3b3b3b

.manuals td img 
    border 1px solid #f5f5f5

#product-tabs input
    width 100%

#product-tabs textarea
    width 100%

/*
   ____                                       ____                  
  / ___|___  _ __ ___  _ __   __ _ _ __ ___  |  _ \ __ _  __ _  ___ 
 | |   / _ \| '_ ` _ \| '_ \ / _` | '__/ _ \ | |_) / _` |/ _` |/ _ \
 | |__| (_) | | | | | | |_) | (_| | | |  __/ |  __/ (_| | (_| |  __/
  \____\___/|_| |_| |_| .__/ \__,_|_|  \___| |_|   \__,_|\__, |\___|
                      |_|                                |___/      
*/
#compare 
    margin 0
    padding 0
    background #fff
    border-collapse collapse

#compare th 
    padding 5px
    font-family Arial, Helvetica, sans-serif
    font-size 90%
    font-weight bold
    color #fff
    background #3b3b3b

#compare td 
    padding 8px
    font-family Arial, Helvetica, sans-serif
    font-size 90%
    color #333
    border-bottom 1px solid #cccccc
    border-right 1px solid #fff

#compare tr.detail 
    background #d0d0d0 url("../images/td-bg.gif") repeat-x left bottom

#compare tr.detail-alt 
    background #fff

/*Contact*/
.cola, .colb 
    margin 0
    padding 0 10px 0 0
    width 260px
    float left

.accountcola, .accountcolb 
    margin 0
    padding 0 10px 0 0
    width 350px
    float left


/*Footer*/
#footer 
    margin 0
    padding 15px 0

#foot-left 
    margin 0
    padding 0 10px 0 0
    float left
    width 310px

#foot-left p 
    margin 0
    padding 0
    font-family Arial, Helvetica, sans-serif
    font-size 90%
    line-height 140%
    color #f5f5f5

dl.foot 
    margin 0
    padding 0 10px 0 0
    float left
    width 145px

dl.foot-categories 
    margin 0
    padding 0 10px 0 0
    float left
    width 200px

dl.foot dt, dl.foot-categories dt 
    margin 0
    padding 0
    font-family Arial, Helvetica, sans-serif
    font-size 90%
    font-weight bold
    color #f5f5f5
    line-height 130%

dl.foot dd, dl.foot-categories dd 
    margin 0
    padding 0
    font-family Arial, Helvetica, sans-serif
    font-size 90%
    line-height 140%
    color #999

dl.foot dt a, dl.foot-categories dt a 
    color #f5f5f5
    text-decoration none

dl.foot dt a:hover, dl.foot-categories dt a:hover 
    color #f5f5f5
    text-decoration underline

dl.foot dd a, dl.foot-categories dd a 
    color #999
    text-decoration none

dl.foot dd a:hover, dl.foot-categories dd a:hover 
    color #999
    text-decoration underline


#metro-credit
    float right
    font-family Arial, sans-serif
    display block
    margin-top 30px


/*.split-container .carousel-control.right */
    


/*
   ____           _   
  / ___|__ _ _ __| |_ 
 | |   / _` | '__| __|
 | |__| (_| | |  | |_ 
  \____\__,_|_|   \__|
                      
*/
a#btn-checkout 
    margin 0
    padding 0
    background url("../images/btn-checkout.gif") no-repeat left top
    height 26px
    width 140px
    display block

a#btn-shop 
    margin 0
    padding 0
    background url("../images/btn-shop.gif") no-repeat left top
    height 26px
    width 140px
    display block

a#btn-checkout span, a#btn-shop span 
    display none

#cart 
    margin 0
    padding 0 30px

#cart h2 
    margin 0
    padding 0
    font-family Arial, Helvetica, sans-serif
    font-size 170%
    line-height 130%
    color #555

#shopping-cart 
    margin 0
    padding 0
    background #fff
    border-collapse collapse

#shopping-cart th 
    padding 5px
    font-family Arial, Helvetica, sans-serif
    font-size 90%
    font-weight bold
    color #fff
    background #3b3b3b

#shopping-cart td 
    padding 7px
    font-family Arial, Helvetica, sans-serif
    font-size 90%
    color #333
    border-bottom 1px solid #ccc

#shopping-cart td.product img 
    padding 3px 5px
    float left
    width 45px

#shopping-cart td.price,
#shopping-cart td.total,
#shopping-cart td.details,
#shopping-cart td.conf 
    text-align center
    width 10%

#shopping-cart td.quantity 
    text-align center
    width 20%

tr.aggregate 
    font-weight bold

tr.alt 
    background url("../images/tools-bg.gif") repeat-x left bottom
    border-bottom 1px solid #ccc

tr.std 
    background #fff


/*
   ____           _      ___        _   _                 
  / ___|__ _ _ __| |_   / _ \ _ __ | |_(_) ___  _ __  ___ 
 | |   / _` | '__| __| | | | | '_ \| __| |/ _ \| '_ \/ __|
 | |__| (_| | |  | |_  | |_| | |_) | |_| | (_) | | | \__ \
  \____\__,_|_|   \__|  \___/| .__/ \__|_|\___/|_| |_|___/
                             |_|                          
*/
#cart_option 
    margin 10px 0 0 0
    padding 0
    background #fff
    border-collapse collapse

#cart_option td 
    padding 3px
    font-family Arial, Helvetica, sans-serif
    font-size 90%
    color #333
    border-bottom 1px solid #ccc

#cart_option th a 
    color #fff
    font-weight normal

#cart_option td a 
    color #000000
    font-weight bold

#buttons, p#cart-empty 
    margin 0
    padding 0 0 10px 0


#help 
    margin 10px 0 0 0
    padding 10px 0 0 0
    border-top 1px solid #ccc

dl.tips 
    margin 0
    padding 0
    float left
    width 180px

dl.tips dt 
    margin 0
    padding 0 0 3px 0
    font-family Arial, Helvetica, sans-serif
    font-size 100%
    font-weight bold
    color #555

dl.tips dd 
    margin 0
    padding 0 0 3px 0
    font-family Arial, Helvetica, sans-serif
    font-size 90%
    color #555

dl.video 
    margin 0
    padding 0 10px 10px 0
    float left
    width 260px

dl.video dt 
    margin 0
    padding 0 0 3px 0
    font-family Arial, Helvetica, sans-serif
    font-size 90%
    color #555

dl.video dd 
    margin 0
    padding 0 0 3px 0
    font-family Arial, Helvetica, sans-serif
    font-size 90%
    color #555

.cards 
    margin 0
    padding 20px 0 0 0
    text-align right

.vid-icon 

    padding 0 6px 0 0
 

/*
   ____ _               _               _   
  / ___| |__   ___  ___| | _____  _   _| |_ 
 | |   | '_ \ / _ \/ __| |/ / _ \| | | | __|
 | |___| | | |  __/ (__|   < (_) | |_| | |_ 
  \____|_| |_|\___|\___|_|\_\___/ \__,_|\__|
                                            
*/
fieldset#shipping, fieldset#billing 
    margin 0
    padding 0
    border 0
    width 400px
    float left

#cart fieldset legend 
    margin 0
    padding 0 0 8px 0
    font-family Arial, Helvetica, sans-serif
    font-size 130%
    font-weight bold
    color #555

#cart fieldset.full 
    float none
    margin 0 auto


/* 
  _   _                    _                             _   
 | | | |___  ___ _ __     / \   ___ ___ ___  _   _ _ __ | |_ 
 | | | / __|/ _ \ '__|   / _ \ / __/ __/ _ \| | | | '_ \| __|
 | |_| \__ \  __/ |     / ___ \ (_| (_| (_) | |_| | | | | |_ 
  \___/|___/\___|_|    /_/   \_\___\___\___/ \__,_|_| |_|\__|
                                                             
 */
img.captcha 
    border 1px solid #999999
    cursor pointer


/* Product Comparison */
/*#compareItemsWrapper 
    margin 0
    padding 0
*/
.compareImage 
    background url("/img/bgComparePD.gif") top left
    width 25px
    height 25px
    cursor pointer

.compareImage img 
    width 25px
    height 25px
    cursor pointer

ul#list_compare 
    margin 0
    padding 0

/*
#list_compare li 
    list-style none
    float left
    height 40px
*/
/*
.compareBox 
    border 1px solid #B5B5B5
    height 25px !important
    overflow hidden
*/
.compareBox .removeCompare 
    width 10px
    height 10px
    position relative
    right -16px
    top -25px
    display block
    cursor pointer

.compareBox .removeCompare img 
    width 10px
    height 10px

.compareSubmit 
    margin 0
    padding 0 0 0 5px


/*
  ____                                  _     ____                  
 |  _ \ __ _ _   _ _ __ ___   ___ _ __ | |_  |  _ \ __ _  __ _  ___ 
 | |_) / _` | | | | '_ ` _ \ / _ \ '_ \| __| | |_) / _` |/ _` |/ _ \
 |  __/ (_| | |_| | | | | | |  __/ | | | |_  |  __/ (_| | (_| |  __/
 |_|   \__,_|\__, |_| |_| |_|\___|_| |_|\__| |_|   \__,_|\__, |\___|
             |___/                                       |___/      
*/
fieldset.paymentcol1, fieldset.paymentcol2 
    margin 0
    padding 0 10px 0 0
    border 0
    width 180px
    float left
    font-family Arial, Helvetica, sans-serif
    font-size 90%
    line-height 140%
    color #3b3b3b

fieldset.paymentcol3 
    margin 0
    padding 10px
    border 1px solid #cccccc
    background #f7f7f7
    width 475px
    float left
    font-family Arial, Helvetica, sans-serif
    font-size 90%
    line-height 140%
    color #3b3b3b

.cc-date 
    width 100px

.cc-year 
    width 50px


.clearfix:after 
    content "."
    display block
    height 0
    clear both
    visibility hidden

.clearfix display inline-block

/* Hides from IE-mac \*/
* html .clearfix height 1%
.clearfix display block
/* End hide from IE-mac */

/*
  ____                     _                           _     
 | __ ) _ __ ___  __ _  __| | ___ _ __ _   _ _ __ ___ | |__  
 |  _ \| '__/ _ \/ _` |/ _` |/ __| '__| | | | '_ ` _ \| '_ \ 
 | |_) | | |  __/ (_| | (_| | (__| |  | |_| | | | | | | |_) |
 |____/|_|  \___|\__,_|\__,_|\___|_|   \__,_|_| |_| |_|_.__/ 
                                                             
*/
#crumb 
    color #428bca


#crumb ul 
    margin 0
    padding 0
    list-style none

#crumb ul li 

    display inline

/*
li.sub 
margin 0 0 0 5px
padding 0 0 0 18px
background url(../images/crumb.gif) no-repeat left 1px
*/

/*Main Layout*/
#content 
    margin 0
    padding 0 20px
    width 540px
    float left

#contentfull 
    margin 0
    padding 0 0 0 20px
    width 740px
    float left

#content-product 
    margin 0
    padding 0

#main-wrap 
    margin-left auto
    margin-right auto
    padding 0 15px 15px 15px
    margin-top 99px


#crumb 
    margin-top 15px
    margin-bottom 5px
    li 
        a 
        color $hanson-blue
            &:hover
                color #FFF
                text-decoration none
    li.sub 
        content "/"
        color $hanson-blue
        padding-left 0


/*Sidebar*/
.btn-filter
    margin-top 20px


#prod-sidebar h5, #help-info h5, #gen-sidebar h5, #related h5 
    margin 0
    padding 15px 0 15px 10px
    font-size 22px
    color #000000
    background-color $hanson-light-grey


.shop-page .sidebar 
    border solid 1px #67727a


#prod-sidebar ul, #gen-sidebar ul 
    margin 0
    padding 0
    list-style none

#prod-sidebar ul li, #gen-sidebar ul li 
    margin 0
    padding 0 0 3px 0
    color #555
    font-size 85%
    padding-left 25px
    line-height 130%


#prod-sidebar ul li a, #gen-sidebar ul li a, #prod-sidebar p a 
    color #67727a
    text-decoration none
    &:hover
        color $blue
        
#prod-sidebar ul li,
#gen-sidebar ul li,
#prod-sidebar p
    .toggleLink
        color $green
        &:hover
            color $green

#prod-sidebar ul li a:hover, #gen-sidebar ul li a:hover, #prod-sidebar p a:hover 
    color $blue
    text-decoration underline


#filters, #cat-desc, #help-info, #gen-sidebar 
    margin 0
    padding 0
    background #f6f5f3
    border 0


#product-image 
    margin 0
    padding 20px 0
    border 1px solid #67727a


#cat-desc p, #help-info p, #gen-sidebar p, #help-info li, #filters p 
    margin 0
    padding 0 0 5px 0
    font-size 90%
    font-weight bold
    line-height 150%
    // color #67727a
    color $green


#price-info 
    margin 0


ul#tools  
    margin 0
    padding 15px 0 0 0
    list-style none


ul#tools li
    margin-bottom 15px

ul#tools .btn
    border-radius 0




/*
____            _   _               _   _                _ 
/ ___|  ___  ___| |_(_) ___  _ __   | | | | ___  __ _  __| |
\___ \ / _ \/ __| __| |/ _ \| '_ \  | |_| |/ _ \/ _` |/ _` |
___) |  __/ (__| |_| | (_) | | | | |  _  |  __/ (_| | (_| |
|____/ \___|\___|\__|_|\___/|_| |_| |_| |_|\___|\__,_|\__,_|

*/
.section-head 
    margin 50px 0 30px 0
    background $alklean-light-grey
    padding 15px


#list_compare li 
    list-style none
    float left
    height 28px


.compareBox 
    border 1px solid #B5B5B5
    height 28px
    background #fff
    overflow hidden


.result-totals 
    margin 0
    padding 0 15px 3px 15px
    width 255px
    font-size 90%
    color #67727a
    float left
    font-weight 700

#compareItemsWrapper
    .btn-info
        transition-all() 
        &:hover, &:focus
            background-color $alklean-green
            border-color $alklean-green
            color $white
            transition-all() 


/*
 ____             _             
|  _ \ __ _  __ _(_)_ __   __ _ 
| |_) / _` |/ _` | | '_ \ / _` |
|  __/ (_| | (_| | | | | | (_| |
|_|   \__,_|\__, |_|_| |_|\__,_|
            |___/ 
*/
.paging 
    margin 0
    padding 0
    width 255px
    float left

/*.result-totals 
margin 0
padding 0 0 3px 0
width 255px
font-family Arial, Helvetica, sans-serif
font-size 90%
color #555
float left
*/
.paging ul, .paging-bottom ul 
    margin 0
    padding 0 0 3px 0
    text-align right
    list-style none

.paging ul li, .paging-bottom ul li 
    font-size 24px
    
    display inline

.paging ul li a, .paging-bottom ul li a 
    margin 0 1px
    padding 2px 6px
    
    text-decoration none
    font-weight 700

.paging ul li a:hover, .paging ul li.active a, .paging-bottom ul li a:hover, .paging-bottom ul li.active a 
    
    font-weight 700
    text-decoration none


/*
____                _            _       
|  _ \ _ __ ___   __| |_   _  ___| |_ ___ 
| |_) | '__/ _ \ / _` | | | |/ __| __/ __|
|  __/| | | (_) | (_| | |_| | (__| |_\__ \
|_|   |_|  \___/ \__,_|\__,_|\___|\__|___/

*/

#product 
    margin 0
    padding 0 0 50px 0



.prod 
    margin 0 0 10px 0
    padding 30px 0 30px 0
    border-bottom 1px solid #ccc


.prod-image 
    margin 0
    padding 0 10px 0 0
    width 110px
    float left

.prod-details 
    margin 0
    padding 0
    max-width calc(100% - 120px)
    font-size 90%
    line-height 130%
    float left

.prod-title 
    margin 0
    padding 0 0 3px 0
    font-size 24px
    line-height 130%
    color #000

.prod-title 
    font-weight bold

.prod-title a 
    color $hanson-blue
    text-decoration none

.prod-title a:hover 
    color $hanson-blue
    text-decoration none
    font-weight bold

.prod-sku, .prod-price 
    margin 0
    padding 0 0 3px 0
    color #000

.prod-sku 
    margin 0
    padding 0 0 3px 0
    color #000

.prod-price 
    margin 0
    padding 0 0 3px 20px
    color #000
    float right
    overflow hidden
    font-weight bold


.gutter 
    padding 10px


.product-tabs li a 
    margin 0
    padding 4px 6px
    font-size 90%
    font-weight bold
    color $hanson-blue
    text-decoration none


.product-tabs li a:hover 
    color #67727a


#products h3 



dl.filter-category dd 
    font-size 16px
    margin-bottom 15px


.cat-desctop p 
    margin-bottom 15px


.search-button 
    height 23px
    position absolute
    right 7px
    top 7px
    background-color #fff


.search-box 
    height 35px
    border solid 2px #67727a
    width 100%
    padding 5px 0 0 5px
    font-weight 400
    color $hanson-blue


dl.filter 
    position relative


.search-descrip 
    font-weight 400
    line-height 1.2
    color $hanson-blue



dl.filter dt 
    margin 10px 0 0 0
    font-size 90%



.tab-pane 
    background-color $hanson-light-grey
    padding 20px


body .nav-tabs>li.active>a, body .nav-tabs>li.active>a:hover, body .nav-tabs>li.active>a:focus 
    color #67727a
    cursor default
    background-color $hanson-light-grey
    border none
    border-radius 0


body .nav-tabs > li > a 
    color #67727a


body .nav-tabs > li > a:hover 
    border-radius 0
    color $hanson-blue
    background-color #f6f5f3


body .tab-content>.active 
    margin-top -1px
    position relative
    z-index 2


body .nav-tabs 
    border-bottom none

body .modal-content
    border-radius 0


.product-preview
    margin auto
    display block


.modal-body img
    margin auto
    display block
    

    
.table-accessories
    width 100%
    background-color #fff
    border solid 1px $hanson-blue
    tr 
        border-top solid 1px $hanson-blue !important
        border-bottom solid 1px $hanson-blue !important
        height 60px 
        td
            padding 3px
    .img 
        text-align center
        width 65px
    a 
        font-size 90%
        font-weight bold
        text-decoration none
        text-transform uppercase
        &:hover
            font-weight bold
    .accessory
        padding 0 0 0 1em

.copy ul, .copy ol 
    margin-top 0
    margin-bottom 0
    padding-top 0
    padding-bottom 8px

.copy ul 
    list-style square

.copy ul li, .copy ol li 
    margin 0
    padding 0
    font-size 90%
    line-height 150%
    color #3b3b3b


.support-video .modal-body 
    background #000


.support-video a 
    -webkit-transition all .3s ease-in-out
    -moz-transition all .3s ease-in-out
    -ms-transition all .3s ease-in-out
    -o-transition all .3s ease-in-out
    transition all .3s ease-in-out


.support-video a:hover, .support-video a:focus 
    text-decoration none
    width 100%
    -webkit-transition all .3s ease-in-out
    -moz-transition all .3s ease-in-out
    -ms-transition all .3s ease-in-out
    -o-transition all .3s ease-in-out
    transition all .3s ease-in-out


.support-video a img
    opacity .7
    width 100% !important
    -webkit-transition all .3s ease-in-out
    -moz-transition all .3s ease-in-out
    -ms-transition all .3s ease-in-out
    -o-transition all .3s ease-in-out
    transition all .3s ease-in-out


.support-video a:hover img, .support-video a:focus img 
    opacity 1
    width 100% !important
    -webkit-transition all .3s ease-in-out
    -moz-transition all .3s ease-in-out
    -ms-transition all .3s ease-in-out
    -o-transition all .3s ease-in-out
    transition all .3s ease-in-out


.support-video h3 
    font-size 21px
    
/*mediass*/
+below(767px)
    #content-product table 
        font-size 14px
        border solid 1px $hanson-blue
        
    #product-center .nav-tabs>li
        width 50%
        margin-bottom 15px

    #content-product table td
        border solid 1px $hanson-blue
        padding 2px
    

    body h1, body .h1, .page-title h1 
        font-size 25px
    

    .page-title h1 
        margin 20px 0 12px 0
    

    article h2 
        font-size 22px
    

    .callout-constant h2 
        margin-top 0

    

    .callout-constant .btn 

    

    body .navbar-brand img 
        width 200px
    

    body .navbar-toggle 

        // margin-right 0px
    

    .navbar-collapse 
        margin-top 10px
    

    body .small .navbar-brand img 
        width 155px
    

    #prod-sidebar .gutter, #alt-sidebar .gutter, #help-info .gutter 
        padding 10px
    

    .gutter 
        padding 0
    

    .filter-category.col-sm-6 
        padding 0
    

    .paging ul, .paging-bottom ul 
        margin 0
        padding 0 0 3px 0
        text-align left
        list-style none
    

    header.large .navbar-nav>li>.dropdown-menu, header .navbar-nav>li>.dropdown-menu 
        margin-top 10px
    

    textarea 
        width 100%
    

    article 
        margin 0
    

    .sub .font-icons .ico 
        font-size 90px
      
    

    iframe 
        width 100%
    


/*
  ____          _ _       _                 _____     _     
 / ___|_      _(_) |_ ___| |__   ___ _ __  |_   _|_ _| |__  
 \___ \ \ /\ / / | __/ __| '_ \ / _ \ '__|   | |/ _` | '_ \ 
  ___) \ V  V /| | || (__| | | |  __/ |      | | (_| | |_) |
 |____/ \_/\_/ |_|\__\___|_| |_|\___|_|      |_|\__,_|_.__/ 
                                                            
*/

.switch-tab 
    position fixed
    top 50%
    left 0
    max-width 300px
    z-index 9999


.switch-tab .menu-hide 
    -webkit-transition all .3s ease-in-out
    -moz-transition all .3s ease-in-out
    -ms-transition all .3s ease-in-out
    -o-transition all .3s ease-in-out
    transition all .3s ease-in-out
    width 0 !important
    overflow hidden
    padding-left 0 !important
    padding-right 0 !important


.switch-tab .button-containter 
    padding 15px
    float left
    -webkit-transition all .3s ease-in-out
    -moz-transition all .3s ease-in-out
    -ms-transition all .3s ease-in-out
    -o-transition all .3s ease-in-out
    transition all .3s ease-in-out
    width 140px
    overflow hidden


.switch-tab .slide-toggel 
    float right
    padding 15px
    cursor pointer


.switch-tab .slide-toggel .glyphicon
    font-size 24px


.switch-tab .slide-toggel, .switch-tab .button-containter 
    background-color hsla(202, 0%, 73%, 0.7)
    /*box-shadow 0 1px 5px 2px hsla(0,0%,0%,0.2)*/


.switch-tab .btn 
    margin-bottom 15px
/*Tests*/

.switch-tab a.btn-info, .switch-tab a.btn-info:visited 
    background-color #428bca
    border-color #357ebd
    color #ffffff
    font-family helvetica, calibri, arial, sans-serif
    font-size 16px


.switch-tab 
    a.btn-info:focus
        background-image none
        background-color #3071a9
        border-color #285e8e

.switch-tab
    a.btn-info:hover
        border-color #285e8e
        background-image none
        background-color #3071a9
        