@import 'import'


rupture.scale = 0 479px 767px 991px 1201px
rupture.anti-overlap = 1px

// Variables
$green = #47AB48
$blue = #0293D2
$dkgreen = #005F43
$alklean-green = #47ab48
$font-stack-open-bold = 'Open Sans', helvetica, arial, sans-serif
$font-stack-body = 'Exo 2', helvetica, arial, sans-serif
$font-stack-headers = 'Hammersmith One', sans-serif

@import "pcs-style"

// Mixins
transition-all() 
    -webkit-transition all .3s ease-in-out
    -moz-transition all .3s ease-in-out
    -ms-transition all .3s ease-in-out
    -o-transition all .3s ease-in-out
    transition all .3s ease-in-out


transition-color() 
    -webkit-transition color .3s ease-in-out
    -moz-transition color .3s ease-in-out
    -ms-transition color .3s ease-in-out
    -o-transition color .3s ease-in-out
    transition color .3s ease-in-out


transition-background() 
    -webkit-transition background .3s ease-in-out
    -moz-transition background .3s ease-in-out
    -ms-transition background .3s ease-in-out
    -o-transition background .3s ease-in-out
    transition background .3s ease-in-out


@font-face 
    font-family 'icomoon'
    src    url('/fonts/icomoon.eot?3i7e2r')
    src    url('/fonts/icomoon.eot?3i7e2r#iefix') format('embedded-opentype'),
        url('/fonts/icomoon.ttf?3i7e2r') format('truetype'),
        url('/fonts/icomoon.woff?3i7e2r') format('woff'),
        url('/fonts/icomoon.svg?3i7e2r#icomoon') format('svg')
    font-weight normal
    font-style normal
    



[class^="icon-"], [class*=" icon-"] 
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family 'icomoon' !important
    speak none
    font-style normal
    font-weight normal
    font-variant normal
    text-transform none
    line-height 1

    /* Better Font Rendering =========== */
    -webkit-font-smoothing antialiased
    -moz-osx-font-smoothing grayscale


.icon-email:before 
    content "\e903"

.icon-location:before 
    content "\e904"

.icon-phone:before 
    content "\e905"


.headroom
    transition all 200ms linear


.headroom--pinned 
    -moz-transform translateY(0%)
    -webkit-transform translateY(0%)
    -o-transform translateY(0%)
    -ms-transform translateY(0%)
    transform translateY(0%)


.headroom--unpinned 
    -moz-transform translateY(-100%)
    -webkit-transform translateY(-100%)
    -o-transform translateY(-100%)
    -ms-transform translateY(-100%)
    transform translateY(-100%)





/* 
  ____  _ _             _         _           
 / ___|(_) |_ ___   ___| |_ _   _| | ___  ___ 
 \___ \| | __/ _ \ / __| __| | | | |/ _ \/ __|
 ___) || ||    __/ \__ \ |_| |_| | |  __/\__ \
 |____/|_|\__\___| |___/\__|\__, |_|\___||___/
                            |___/             
 ------------------------------------------- */

html body 
    font-family $font-stack-body
    font-size 18px
    font-weight 300
    line-height 1.5
    img 
        max-width 100%
    hr 
        border 0
        border-bottom 1px solid #333
    h1, h2, h3 
        font-family $font-stack-headers
        text-rendering geometricPrecision
        
a 
    color $green
    &:hover, &:focus
        color $blue

.green
    background-color $green

body small 
    font-size 85%


/* Selection color */
-moz-selection  background #428bca color #fff text-shadow none 
selection  background #428bca color #fff text-shadow none 

/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `before` to contain the top-margins of child elements.
 */
.cfbefore,
.cfafter 
    content " " /* 1 */
    display table /* 2 */


.cfafter 
    clear both


/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.cf 
    *zoom 1


.bottom-space-xs 
    margin-bottom 20px


.bottom-space-sm 
    margin-bottom 40px


.bottom-space-md 
    margin-bottom 60px


.bottom-space-lg 
    margin-bottom 100px

.keep-together
    display inline-block

/* 
   _   _ _____    _    ____  _____ ____  
  | | | | ____|  / \  |  _ \| ____|  _ \ 
  | |_| |  _|   / _ \ | | | |  _| | |_) |
  |  _  | |___ / ___ \| |_| | |___|  _ < 
  |_| |_|_____/_/   \_\____/|_____|_| \_\

  -------------------------------------------------------------------- */

header
    .click-call
        position absolute
        top 15px
        right 0
        font-weight 700
        z-index 10
    background-color $white
    width 100%
    z-index 10
    top 0px
    box-shadow 0 2px 8px rgba(#333, .6)
    .container, .container-fluid
        padding 0
    
    .container-header
        width 100%
        max-width 1400px
        padding 0 50px

            
    .navbar-brand 
        font-size 36px
        font-size 2.25rem
        margin-left 0 !important
        padding-top 0
        padding-left 0
        height auto
    .navbar.navbar-default 
        margin-top 0
        margin-bottom 0
        transition-all()
        background-image none
        background-color transparent
        border none
        box-shadow none
        .navbar-nav 
            clear right
            li 
                a 
                    color $blue
                    background transparent
                    margin 50px 0 30px
                    padding 20px 10px
                    font-family $font-stack-headers
                    font-weight 400
                    text-rendering geometricPrecision
                    font-size 21px
                    transition-color()
                    +between(1199px, 1300px)
                        margin 50px 0 40px
                        font-size 18px
                    
                    &:visited 
                        color $blue
                        background transparent

                    &:hover, &:focus 
                        color $green
                        transition-color()
                    &:focus, &:active
                        color $blue
                        background-color #f9f9f9
                        transition-background()
                    
                .contact-button
                    background $green !important
                    border-radius 4px
                    color #fff !important
                    text-align center
                    transition-all()
                    &:hover
                        background $blue !important
                        transition-all()
                    +below(1199px)
                        margin 0 10px 10px

                ul
                    margin -30px 0 0 0
                    padding 0
                    li
                        border-bottom 1px solid rgba(#ccc, .3)
                        a
                            margin 0
                            padding 10px
                            font-family 'Open Sans'
                            font-size 16px
       
.navbar-right   
    ul.dropdown-menu 
        background #FFF
        border-radius 0
        box-shadow none
        border-left solid 1px $blue
        border-right solid 1px $blue
        border-bottom solid 1px $blue
        border-top none
        margin-top 8px
        right auto !important
        transition-all()

header .dropdown-menu li a, header .dropdown-menu li a:visited 
    color $blue
    @include adjust-font-size-to(18px)
    @include adjust-leading-to(1)
    transition-all()
    

header .dropdown-menu > li > ahover, header .dropdown-menu > li > afocus 
    color $blue


header .navbar-default .navbar-nav>.open>a, header .navbar-default .navbar-nav>.open>ahover, header .navbar-default .navbar-nav>.open>afocus, header .dropdown-menu>li>ahover, header .dropdown-menu>li>afocus 
    color $blue
    background none
    background-color transparent
    transition-all()
    

.sub-header-wrapper
    background-color $blue
    padding 5px 0 15px 0
    margin-bottom 50px
    +below(768px)
        word-break break-all
    h1
        color #ffffff
        text-transform uppercase
        font-size 44px
        margin 0
        line-height 1
    
    #crumb 
        margin 0 0 5px 0
        li
            color #fff
            &:not(:first-child)
                &:before
                    display inline-block
                    content '/'
                    margin 0 5px
        a 
            color #fff

        
        .sub 
            &:before 
                content "/"
                display inline-block
                position relative
                color #ffffff
    
.navbar-toggle
    +below(767px)
        margin 35px
    +below(350px)
        margin-left 0

.carousel-control 
    width 3%

.main-callouts
    border-top 10px solid $green
    .row
        display flex
        +below(767px)
            flex-direction column
    .callout-block
        h2
            color $blue
            margin-top 10px
        border-right 1px solid #666
        +below(767px)
            border-right 0
        text-align center
        padding 15px
        &:last-child
            border-right 0
            
            

/* 
   ____ _____  _   _ _____ _____ _   _ _____ 
  / ___/ __  \| \ | |_   _| ____| \ | |_   _|
  | |  | | |  |  \| | | | |  _| |  \| | | |  
  | |__| |_|  | |\  | | | | |___| |\  | | |  
  \____\_____/|_| \_| |_| |_____|_| \_| |_|  

  --------------------------------------------------------------------- */

#results
    font-size 21px
    .title
        font-weight 400
    .description
        font-size 18px
    .url
        color $blue
        font-size 18px
        
.SuggestFramework_List
    display none
    border 0
    
body .SuggestFramework_Normal
    background-color #ececec

#powered_by
    margin-bottom 40px
    
.search-results-button
    .btn-primary
        font-size 18px
        margin-left 15px
        padding-top 4px
        padding-bottom 5px

.shop-page #product-tabs li 
    display none

body #carousel-products
    +between(991px, 1099px)
        width 90%
        margin auto
    +between(767px, 880px)
        width 90%
        margin auto
    +below(767px)
        padding-bottom 60px
        

body #carousel-products .carousel-control.right,
body #carousel-products .carousel-control.left
    background-image none
    filter none
    +below(767px)
        top unset
        bottom 40px
        margin 0 200px
    +below(550px)
        bottom 60px
        margin 0 100px

.carousel-control.left span,
.carousel-control.right span
    margin-top -30px
    +below(550px)
        margin-top 0
    
.carousel-control.left
    left -25px
    
.carousel-control.right
    right -25px


#carousel-products .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next 
    right 0


#carousel-products .carousel-control .glyphicon-chevron-left, .carousel-control .icon-prev 
    left -17px


#carousel-products .carousel-control 
    color $alklean-medium-grey
    opacity 1



#carousel-products .carousel-controlhover, 
#carousel-products .carousel-controlfocus 
    color $alklean-medium-grey
    opacity .7


#carousel-products .carousel-control .glyphicon 
    font-size 50px


.feature-row
    margin-right auto 
    margin-left auto
    padding 50px 0
    width 100%
    
    h1
        padding 0 0 50px 0
        color $blue
        text-transform uppercase

    #wrench
        float left
        display inline-block
        +below(991px)
            float none
            display block
            text-align center
            margin-bottom 50px
    .customer-service-text
        +below(991px)
            margin 50px auto 25px
            float none
        
    .btn
        background-color $dkgreen
        color $white

.split 
    h2 
        font-size 36px
        font-weight 400
        font-family $font-stack-headers

.main-callout
    margin-top 150px
    background url(/img/home-bg.png)
    background-position center 0
    background-repeat no-repeat
    background-size cover
    +below(767px)
        margin-top 113px;
    +below(480px)
        background-position center -10px

.callout .btn-primary 
    margin 15px 0 0 0


.home
    // background url(/img/home-bg.jpg) no-repeat center center fixed
    background-position center center
    background-repeat no-repeat
    section.main
            margin-bottom 0
            margin-top 0
    .overlay
        background-color rgba(#111, .6)
    .callout
        color $white
        padding 50px 0
        display flex
        justify-content center
        flex-direction column
        h1 
            color $white
            margin-top 0
            font-size 60px
            font-weight 400
            font-family $font-stack-headers
            text-shadow 2px 4px 18px rgba(#111, .6)
            +below(767px)
                font-size 36px
                line-height 1
            +below(350px)
                font-size 32px
        p
            text-shadow 2px 4px 18px rgba(#111, .6)
            font-weight 700
            font-size 21px
        a
            color $blue
            background #fff
            font-size 24px
            font-weight bold
            font-family $font-stack-open-bold
            transtion-background()
            transition-color()
            &:hover, &:focus
                background $blue
                color $white
                transition-background()
                transition-color()
            &.btn-green
                color $white
                background $green
                &:hover, &:focus
                    background $white
                    color $green
        .buttons-main
            margin 15px 0
            .btn-green
                margin-left 15px
                +below(767px)
                    margin-left 0
                    margin-top 15px;
                    display block
                    max-width 300px
            
                
    .callout-sub
        background-color #fff
        border-radius 2px
        box-shadow 0 2px 4px rgba(#111, .4)
        a
            text-decoration none
            color $blue
            &:hover, &:focus
                text-decoration none
                color $blue
                h3
                    color $alklean-dark-indigo
                    text-decoration none
        h3
            margin-top 0
            font-size 25px
            font-weight 300
            text-decoration none
            font-family $font-stack-headers
            color $alklean-dark-indigo
            text-decoration none
        color $blue
        font-weight 300
        padding 15px
        text-align center
        margin 30px 0
        
            
    .main 
        margin-bottom 0
        background-color $white
    
    .main .green
        position relative
        &:before
            content ''
            display block
            position absolute
            width 200vw
            height 100%
            background-color $green
            top 0
            left -100%
            


.callout-constant 
    display none
    background-color #dbdddd !important
    color $alklean-medium-grey
    padding 30px 0
    .btn
        margin 10px auto 0 auto
        max-width 142px
        display block
        clear both
    h2 
        font-weight 700
        text-align center
        color $alklean-medium-grey
        font-family $font-stack-headers

.main .list-intro 
    margin-bottom 18px


body .breadcrumb 
    margin-bottom 0
    margin-top 0
    background-color transparent


#crumb 
    ol 
        padding-left 0
    
    li.sub 
        color $white
    
/*Primary Button Styles*/
body .btn-primary, body input[type=submit] 
    background-color $alklean-green
    background-image none
    border 0px
    font-size 24px
    font-family $font-stack-roboto
    text-rendering geometricPrecision
    transition-background()
    &:visited
        background-image none
        border 0px
    &:hover, &:focus 
        background-color $blue 
        background-image none
        border 0px
        transition-background() 
        
body .home .btn-primary 
    &:hover, &:focus
        background-color $alklean-dark-grey

        
body .btn-default
    background-color $alklean-green
    background-image none
    border 0px
    color #fff
    font-size 24px
    font-family $font-stack-roboto
    text-rendering geometricPrecision
    transition-background()
    &:hover, &:focus 
        background-color $white
        background-image none
        border 0px
        color $alklean-green
        transition-background()
        
    



section.main 
    background-color $white
    width 100%
    min-height 350px
    overflow hidden
    margin-top 60px
    margin-bottom 60px


.shop-page section.main, .products-page section.main 
    margin-top 150px



.lead-in 
    position absolute
    left 47%
    top 0
    width 0
    height 0
    border-style solid
    border-width 20px 17.5px 0 17.5px
    border-color #FFF transparent transparent transparent


body .half-one 
    background $alklean-dark-indigo
    min-height 325px




body .half-two 
    background $alklean-medium-grey
    min-height 325px
    h2, p
        color $alklean-dark-indigo




.half-one .split-container 
    /*margin-left auto
    display block
    width 76%-*/



.split-container 
    color #fff
    min-height 290px !important
    h2 
        margin-top 40px
        text-align center
        color #fff
    
    p 
        text-align center
        color #fff
    
    .carousel-control.right 
        background-image none
    
    .carousel-inner 
        margin-top 60px
        p 
            font-size 21px
            text-align left
            margin-top 20px
        
    
    .glyphicon-chevron-rightbefore 
        margin-left 39px
        font-size 40px
        opacity 1
    
    .carousel-control 
        opacity 1
        text-shadow none
    

    .button-outter
        position absolute
        left 0
        bottom 0
        width 100%
    
    a 
        max-width 146px
        display block
        margin 15px auto 15px auto

    


.support-video 
    h3 
        font-size 21px
        line-height 1.25
        text-align center
    


.font-icons 
    margin 25px auto
    display block
    text-align center


.font-icons .ico 
    font-size 100px


body .btn-info 
    background-color $blue
    border-color $blue


body .btn-infohover, body .btn-infofocus 
    background-color $alklean-dark-grey
    border-color $alklean-dark-grey


#content-product 
    margin-top 40px


/*
  ____                          _                    ____                  
 / ___| ___  ___ ___  _ __   __| | __ _ _ __ _   _  |  _ \ __ _  __ _  ___ 
 \___ \/ _ \/ __/ _ \| '_ \ / _` |/ _` | '__| | | | | |_) / _` |/ _` |/ _ \
 ___) |  __/ (_| (_) | | | | (_| | (_| | |  | |_| | |  __/ (_| | (_| |  __/
 |____/\___|\___\___/|_| |_|\__,_|\__,_|_|   \__, | |_|   \__,_|\__, |\___|
                                              |___/              |___/      
 ----------------------------------------------------------------------- */

.main-copy p 
    font-size 18px
    line-height  1.4
    margin-bottom 18px
    &:first-child 
        margin-top 18px
    &:last-child 
        margin-bottom 18px
    h2 
        font-size 30px
        line-height 1.1
        margin-top 30px
        margin-bottom 30px
        font-family $font-stack-headers
    ul 
        font-size 18px
        line-height 18px
        margin-top 18px
        margin-bottom 18px

.poem
    p, h2
        text-align center
        
.parts-accessories .main 
   margin-top 18px


.sub section.main 
    background #FFF
    width 100%
    min-height 350px
    padding-top 90px


.sub 
    .callout-constant 
        background $blue
        color #FFF
    .btn 
        background-color #FFF
        color $blue
        margin-top 19px
        &:visited 
            background-color #FFF
            color $blue  
        &:hover, &:focus 
            background-color $blue
            color #FFF
    


body 
    .jumbotron 
        position relative
        height 520px
        margin-top 150px
        background-size cover
        background-position 0 70px
        background-repeat no-repeat
        .band-wrapper 
            background-color $blue
            color #fff
            width 100%
            padding 30px 0 10px 0
            position absolute
            top 0
            height 96px
            margin 0

            article 
                margin 0  
        .page-title
            font-family $font-stack-headers
            width 100%
            color #fff
            font-size 30px
            line-height 1
            margin 0
            +above(768px)
                margin 0
                font-size 40px
                line-height 1
            

        
    

.service .jumbotron
    margin-top 0
    background-image url(/img/banners/service-bg.jpg)


.parts-accessories .jumbotron
    margin-top 0
    background-image url(/img/banners/parts-accessories-bg.jpg)
    background-position right bottom


.rentals .jumbotron
    margin-top 0
    background-image url(/img/banners/rentals-bg.jpg)
    background-position center bottom


.about-mission .jumbotron
    background-image url(/img/banners/about-banner.jpg)
    background-position-y 75px
    background-position-x -600px
    +below(450px)
        background-position-y 50px
    
.about.gone-fishing .jumbotron
    margin-top 0
    background-image url(/img/banners/gone-fishing.jpg)
    background-position center bottom


.support .jumbotron
    background-image url(/img/banners/support-bg.jpg)
    background-position center bottom


.faq .jumbotron
    // margin-top 0
    background-image url(/img/banners/support-bg.jpg)
    background-position center bottom


.contact .jumbotron
    background-image url(/img/banners/contact-banner.jpg)
    background-position center bottom


.faq .page-title, .tips .page-title
    width 100%
    color #fff
    text-transform uppercase
    font-size 50px
    line-height 1
    margin 0
    h1 
        margin 10px 0 0 0
        
.all-equip
    .sub-header-wrapper
        margin-top 150px
    .main
        h2
            text-align center
        p
            text-align center

.water-treatment .jumbotron
    background-image url(/img/banners/water-treatment.jpg)
    background-position center center
            
.all-equip .jumbotron
    background-image url(/img/banners/equipment.jpg)
    background-position center center
    
.mechanical-filtration .jumbotron
    background-image url(/img/banners/mechanical-filtration.jpg)
    background-position center center
    
.bio-digester .jumbotron
    background-image url(/img/banners/bio-digesters.jpg)
    background-position center center
    
.ionic-agglomeration .jumbotron
    background-image url(/img/banners/ionic-agglomeration.jpg)
    background-position center center
    
.oil-water-separators .jumbotron
    background-image url(/img/banners/oil-water-separators.jpg)
    background-position center center

.pit-systems .jumbotron
    background-image url(/img/banners/pit-systems.jpg)
    background-position center center
    
.formed-trenching .jumbotron
    background-image url(/img/banners/formed-trenching.jpg)
    background-position center center
    
.wash-rack-design .jumbotron
    background-image url(/img/banners/wash-rack-design.jpg)
    background-position center center

.available-services .jumbotron
    background-image url(/img/banners/available-services.jpg)
    background-position center center

.company-overview .jumbotron
    background-image url(/img/banners/about-mission-bg.jpg)
    background-position center center
    background-position-x right
    +below(1450px)
        background-position-x -800px
    +below(1250px)
        background-position-x -1000px
    +below(1050px)
        background-position-x -1100px
    +below(768px)
        background-position-x -400px
        background-position-y 30px
    +below(625px)
        background-position-x -500px
    +below(450px)
        background-position-x -550px
    
.careers .jumbotron
    background-image url(/img/banners/careers.jpg)
    background-position center center
    
.tech-tips .jumbotron
    background-image url(/img/banners/tech-tips.jpg)
    background-position center center
    
.homeward .jumbotron
    background-image url(/img/banners/homeward.jpg)
    background-position center center
    
.our-team .jumbotron
    background-image url(/img/banners/our-team.jpg)
    background-position center center
    
.detergents .jumbotron
    background-image url(/img/banners/detergents.jpg)
    background-position center center
    
.water-treatment-chemicals .jumbotron
    background-image url(/img/banners/water-treatment-chemicals.jpg)
    background-position center center
    
.graffiti-removal .jumbotron
    background-image url(/img/banners/graffiti-removal.jpg)
    background-position center center
    
.concrete-removal .jumbotron
    background-image url(/img/banners/concrete-removal.jpg)
    background-position center center
    
.metal-treatment .jumbotron
    background-image url(/img/banners/metal-treatment.jpg)
    background-position center center

.sds .jumbotron
    background-image url(/img/banners/sds.jpg)
    background-position center center
    
.faq-intro 
    li 
        margin-bottom 30px
    
    .question 
        font-style italic
    
    .answer 
        color $blue
    


body .panel-default > .panel-heading 
    background-color $blue
    color $white
    border-radius 0
    padding 0


.panel-title
    &:hover
        background-color $green
        transition-background()
    a 
        padding 10px 15px
        display block
        &:hover, &:focus 
            text-decoration none
            color $white
    


.indicator 
    float right


.panel-heading .accordion-toggle:after 
    /* symbol for "opening" panels */
    font-family 'Glyphicons Halflings'  /* essential for enabling glyphicon */
    content "\e114"    /* adjust as needed, taken from bootstrap.css */
    float right        /* adjust as needed */
    color $white         /* adjust as needed */

.panel-heading .accordion-toggle.collapsed:after 
    /* symbol for "collapsed" panels */
    content "\e080"    /* adjust as needed, taken from bootstrap.css */

body .nav-tabs
    border-bottom 1px solid $blue
    
body .nav-tabs > li
    margin-bottom 0
    
body .nav-tabs > li > a 
    background-color #f6f5f3
    border 1px solid #f6f5f3
    transition-all() 
    &:hover, &:focus
        background-color $green
        border-color $green
        color #fff
        transition-all() 

body .nav-tabs > li.active > a 
    background-color $blue
    border 1px solid $blue
    color $white
    transition-all() 
    &:hover, &:focus
        background-color $blue
        border 1px solid $blue
        color $white
        transition-all()
        
.buttons
    margin-top 15px
    input
        color $white
        border-radius 4px

body .jumbotron 
    background-image url(/img/banners/sample-bg.jpg)
    margin-bottom 0



.band-wrapper
    color #fff
    width 100%
    position absolute
    bottom 0
    article 
        margin 0
    
    aside 
        margin 30px 0 0 0
    


.section-intro 
    text-align center
    p 
        font-size 18px
    


.sub .font-icons 
    .ico 
        font-size 150px
        color $blue
    
    h3 
        color $blue
        font-size 30px
    


article 
    margin 0
    
    h3
        a 
            color $blue
            &:hover, &:focus
                color $green
    h2
        color $blue
        font-size 36px
        margin-top 0
    h1
        margin-top 0
        color $blue
    .post
        padding-top 30px
        padding-bottom 30px
        
        
.widget
    margin-bottom 25px

    
.sidebar 
    border-left 2px solid $gray
    padding-left 30px
    margin-bottom -5000px
    padding-bottom 5000px

/*test--#f6f5f3*/
aside
    margin 0 0 30px 0
    h4
        font-size 24px
        font-family $font-stack-headers
    ul
        list-style-type none
        padding-left 0
        +below(767px)
            padding-top 15px
    
    li
        color #fff
        margin-bottom 5px
        a 
            padding 0    
            display block
            font-size 20px
            background-color transparent
            color $blue
            text-align left
            transition-color()
            &:visited 
                background-color transparent
                color $blue
            
            &:hover, &:focus
                background-color transparent
                color $alklean-green
                text-decoration none
                transition-color()
                
.sidebar-contact-button
    margin-top 25px
    padding 10px
    background $green
    border-radius 4px   
    color #fff !important
    text-align center
    font-weight bold
    transition all .3s ease-in-out
    display block
    &:hover, &:focus
        background $blue
        transtion all .3s eas-in-out 
        text-decoration none
        
.screen-reader-text
    position absolute
    right -9999px
    

.services-page
    .service
        padding-top 25px
        padding-bottom 25px
        border-bottom 1px solid #b9b9b9
        margin-bottom 25px
        .service-icon
            display flex
            align-items center
            justify-content center
        +below(768px)
            .service-desc
                max-width 375px
                margin auto
        +below(420px)
            .service-desc
                text-align center

        +above(768px)
            h3
                margin-top 0
        &:first-of-type
            margin-top 50px
        &:last-of-type
            border-bottom 0


.main hr 
    border-color $blue


dl.category 
    height 240px
    margin-bottom 40px
    img 
        margin auto
        display block
        max-height 178px
    
        
tr.detail td:first-child
    +below(768px)
        position sticky
        left 0
        background-color #b7b4b4
    
tr.detail-alt td:first-child
    +below(768px)
        position sticky
        left 0
        background-color #e0e0e0

#compare tr.detail {
    background: #d0d0d0 !important;
}

textarea.form-control 
    min-height 150px


.required 
    color $blue


.notfound 
    margin-top 100px


.do-dont
    section.main
        overflow visible


/* 
  _____           _            
  |  ___|__   ___ | |_ ___ _ __ 
  | |_ / _ \ / _ \| __/ _ \ '__|
  |  _| (_) | (_) | ||  __/ |   
  |_|  \___/ \___/ \__\___|_|   

  ----------------------------------------------------------------------- */

footer 
    background-color rgba($green, .9)
    color $white
    padding-top 50px
    max-width 100vw
    overflow-x hidden
    // padding-bottom 50px
    .navbar-default 
        margin-left auto
        margin-right auto
        margin-bottom 0
        padding-top 10px
        display block
        width 615px
    
    ul 
        padding 0
        font-size 18px
        list-style-type none
    
    hr 
        margin-top 0
    
    a 
        color $white
        &:visited 
            color $white
        
        &:hover, &:focus 
            color $blue
            text-decoration none
        
    
    .navbar 
        margin-top 0
    

.copyright
    position relative
    margin auto
    display block
    text-align center
    margin-top 20px
    padding 10px 0
    font-size 14px
    background-color $green
    &:before,
    &:after
        content ''
        display block
        width 100%
        height 100%
        background $green
        position absolute
        left -100%
        top 0
    &:after
        left unset
        right -100%


.social span 
    float right


.social .icobefore 
    font-size 45px
    margin-left 4px
    margin-right -4px


.contact .icobefore 
    font-size 24px
    margin-right 8px


.location .icobefore 
    font-size 24px
    margin-right 8px
    padding-top 10px
    display inline-block
    float left


.contact input[type="submit"]
    padding 5px 40px
    background $blue
    transition-background()
    &:hover
        background $green
        transition-background()
        
.modal-footer button
    color #fff
    &:hover
        background $gray

/*Flexslider*/
@font-face
    font-family 'flexslider-icon'
    src url('fonts/flexslider-icon.eot')
    src url('fonts/flexslider-icon.eot?#iefix') format('embedded-opentype'), url('fonts/flexslider-icon.woff') format('woff'), url('fonts/flexslider-icon.ttf') format('truetype'), url('fonts/flexslider-icon.svg#flexslider-icon') format('svg')
    font-weight normal
    font-style normal

.flex-container a:hover,
.flex-slider a:hover
    outline none

.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav
    margin 0
    padding 0
    list-style none

.flex-pauseplay
    span
        text-transform capitalize
    a
        display block
        width 20px
        height 20px
        position absolute
        bottom 5px
        left 10px
        opacity 0.8
        z-index 10
        overflow hidden
        cursor pointer
        color #000
        &:before
            font-family "flexslider-icon"
            font-size 20px
            display inline-block
            content '\f004'
        &:hover
            opacity 1
        &.flex-play
            &:before
                content '\f003'

.flexslider
    margin 0
    padding 0
    margin 0
    background #fff
    border 4px solid #fff
    position relative
    -webkit-border-radius 4px
    -moz-border-radius 4px
    border-radius 4px
    -webkit-box-shadow '' 0 1px 4px rgba(0, 0, 0, 0.2)
    -moz-box-shadow '' 0 1px 4px rgba(0, 0, 0, 0.2)
    -o-box-shadow '' 0 1px 4px rgba(0, 0, 0, 0.2)
    box-shadow '' 0 1px 4px rgba(0, 0, 0, 0.2)
    .slides
        zoom 1
        & > li
            display none
            -webkit-backface-visibility hidden
        img
            width 100%
            display block
            height auto
            -moz-user-select none
        &:after
            content "\0020"
            display block
            clear both
            visibility hidden
            line-height 0
            height 0
    &:hover
        .flex-direction-nav
            .flex-prev
                opacity 0.7
                left 10px
                &:hover
                    opacity 1
            .flex-next
                opacity 0.7
                right 10px
                &:hover
                    opacity 1

html[xmlns]
    .flexslider
        .slides
            display block

*
    html
        .flexslider
            .slides
                height 1%

.no-js
    .flexslider
        .slides
            & > li
                &:first-child
                    display block

.flex-viewport
    max-height 2000px
    -webkit-transition all 1s ease
    -moz-transition all 1s ease
    -ms-transition all 1s ease
    -o-transition all 1s ease
    transition all 1s ease

.loading
    .flex-viewport
        max-height 300px

.carousel
    li
        margin-right 5px

.flex-direction-nav
    *height 0
    a
        text-decoration none
        display block
        width 40px
        height 46px
        margin -20px 0 0
        position absolute
        top 50%
        z-index 10
        overflow hidden
        opacity 0
        cursor pointer
        color rgba(0, 0, 0, 0.8)
        text-shadow 1px 1px 0 rgba(255, 255, 255, 0.3)
        -webkit-transition all 0.3s ease-in-out
        -moz-transition all 0.3s ease-in-out
        -ms-transition all 0.3s ease-in-out
        -o-transition all 0.3s ease-in-out
        transition all 0.3s ease-in-out
        &:before
            font-family "flexslider-icon"
            font-size 40px
            display inline-block
            content '\f001'
            color rgba(0, 0, 0, 0.8)
            text-shadow 1px 1px 0 rgba(255, 255, 255, 0.3)
        &.flex-next
            &:before
                content '\f002'
    .flex-prev
        left -50px
    .flex-next
        right -50px
        text-align right
    .flex-disabled
        opacity 0!important
        cursor default
        z-index -1

.flex-control-nav
    width 100%
    position absolute
    bottom -40px
    text-align center
    li
        margin 0 6px
        display inline-block

.flex-control-paging
    li
        a
            width 11px
            height 11px
            display block
            background #666666
            background rgba(0, 0, 0, 0.5)
            cursor pointer
            text-indent -9999px
            -webkit-box-shadow inset 0 0 3px rgba(0, 0, 0, 0.3)
            -moz-box-shadow inset 0 0 3px rgba(0, 0, 0, 0.3)
            -o-box-shadow inset 0 0 3px rgba(0, 0, 0, 0.3)
            box-shadow inset 0 0 3px rgba(0, 0, 0, 0.3)
            -webkit-border-radius 20px
            -moz-border-radius 20px
            border-radius 20px
            &:hover
                background #333
                background rgba(0, 0, 0, 0.7)
            &.flex-active
                background #000
                background rgba(0, 0, 0, 0.9)
                cursor default

.flex-control-thumbs
    margin 5px 0 0
    position static
    overflow hidden
    li
        width 25%
        float left
        margin 0
    img
        width 100%
        height auto
        display block
        opacity .7
        cursor pointer
        -moz-user-select none
        -webkit-transition all 1s ease
        -moz-transition all 1s ease
        -ms-transition all 1s ease
        -o-transition all 1s ease
        transition all 1s ease
        &:hover
            opacity 1
    .flex-active
        opacity 1
        cursor default
.slider
    margin 50px 0 10px!important
    
//#slider
//    li.flex-active-slide 
//        width 742px !important
//        height 450px !important


#carousel
    li
        margin-right 5px
        width 210px !important
        height auto !important
    img
        display block
        opacity .5
        cursor pointer
        &:hover
            opacity 1
    .flex-active-slide
        
        img
            opacity 1
            cursor default

@media screen and (max-width: 860px)
    .flex-direction-nav
        .flex-prev
            opacity 1
            left 10px
        .flex-next
            opacity 1
            right 10px
        
/* 
   _____                                _           
  |  _  \___  ___ _ __   ___  _ __  ___(_)_   _____ 
  | |_) / _ \/ __| '_ \ / _ \| '_ \/ __| \ \ / / _ \
  |  _ <  __/\__ \ |_) | (_) | | | \__ \ |\ V /  __/
  |_| \_\___||___/ .__/ \___/|_| |_|___/_| \_/ \___|
                                
  ----------------------------------------------------------------------- */

@media (max-width: 479px) 
    header .navbar-default .navbar-brand 
        width 200px
    

    body .navbar-default .navbar-nav .open .dropdown-menu > li > a 
        white-space inherit
    
    #carousel
        li
            margin-right 5px
            width 90px !important
            height auto !important
    


@media (min-width: 1200px) 
    .half-one .split-container 
        width 500px !important
        float right !important 
        margin-right 30px

    

    .half-two .split-container 
        width 550px !important
        float left !important 

    



@media (min-width: 768px) 
    .product-result-container .collapse 
        display block
    



@media (min-width: 992px) 

    .half-one 
        background $blue
        height 325px

    

    .half-two 
        background $blue
        height 325px



    .modal-lg 
        width 840px
    




@media (max-width: 767px) 
    #carousel-products .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next 
        right 20px
    

    #carousel-products .carousel-control .glyphicon-chevron-left, .carousel-control .icon-prev 
        left 20px
    


@media (min-width: 768px) and (max-width: 840px) 
    #carousel-products .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next 
        right 20px
    

    #carousel-products .carousel-control .glyphicon-chevron-left, .carousel-control .icon-prev 
        left 0px
    


@media (max-width: 991px) 
    .half-two .lead-in 
        display none
        
    aside 
        margin: 50px 0 30px 0
    
    




@media (max-width: 767px)
    
    header .navbar-default .navbar-nav>li>a:link
        padding 15px 10px
        font-size 20px

    .home .callout-sub h3 
        font-size 18px
    

    div#compareItemsWrapper 
        float left
    

    .sidebar 
        border-top 2px solid #8b8c8c
        border-left 0
        padding-left 0
        width 100%
    

    aside li a 
        padding-left 0
    

    .shop-page section.main, .products-page section.main 
        margin-top 124px
    

    body .half-one, body .half-two 
        min-height 325px
    

    .split-container 
        min-height 1px !important
        .button-outter 
            position static
        
    

    .section-head 
        margin 20px 0 30px 0
        background #dbdddd
        padding 15px
    

    .social span 
        float left
    

    .half-one 
        padding-top 20px
        padding-bottom 20px

    

    .half-two 
        padding-top 20px
        padding-bottom 20px
    

    .half-one .lead-in 
        top -20px
        left 46%
    

    .split-container h2
        margin-top 0
    

    .callout-constant h2 
        float left
        margin-right 15px
    

    header .navbar-default .navbar-brand 
        color #777
        font-size 24px
        height auto
    

    

    .full-img 
        padding-left 0 !important
        padding-right 0 !important
    

    header .navbar-default .navbar-nav>li>a:link, header .navbar-default .navbar-nav>li>a:visited 
        padding-top 15px
        padding-bottom 15px
        padding-left 10px
        padding-right 10px
    

    body .jumbotron 
        margin-top 69px
        height 250px
        background-size cover
        background-position center center
        .band-wrapper
            padding 20px 0
        +below(767px)
            margin-top 125px
        +below(480px)
            margin-top 115px
            
    section.main
        margin-top 30px
        margin-bottom 30px
    


@media (min-width: 768px) and (max-width: 991px)
    
    body
        header
            .search-container
                margin-left 15px
    
    header
        .navbar.navbar-default 
            .navbar-nav>li>a 
                padding 15px 10px

    body .half-one, body .half-two 
        min-height 325px
    

    .split-container 
        min-height 1px !important
        .button-outter 
            position static
        
    

    aside ul li a:hover, aside ul li a:focus 
        font-size 16px
    

    aside ul li a, aside ul li a:visited 
        font-size 16px
    

    .sub section.main 
        padding-top 128px
    

    header .navbar-right 
        float left !important
        clear left

    

    header .navbar-right li:first-child a 
        padding-left 0
    

    header .navbar-collapse 
        padding-left 0
    

    header .container-fluid 
        padding-left 0
    

    header .navbar-default 
        margin-bottom 0
    

    header .navbar-default .navbar-nav>li>a:link 
        font-size 20px
    



@media (min-width: 768px) and (max-width: 1199px) 

    .home .callout-sub h3 
        min-height 54px
    
    header .navbar-default .navbar-nav>li>a:link 
        font-size 20px


@media (min-width: 991px) and (max-width: 1199px) 

    aside ul li a:hover, aside ul li a:focus 
        font-size 18px
    

    aside ul li a, aside ul li a:visited 
        font-size 18px
    

    .band-wrapper aside 
        margin 10px 0 0 0
    

    .band-wrapper aside p
        margin 0 0 10px 0
    


@media (min-width: 768px) and (max-width: 1199px) 

                
    body .navbar-header 
        float none
    
    body .navbar-left, .navbar-right 
        float none !important
    
    body .navbar-toggle 
        display block
        margin-top 24px
        margin-bottom 10px
        padding-top 15px
        padding-bottom 15px
    
    body .navbar-collapse 
        border-top 0 solid transparent
        box-shadow inset 0 1px 0 hsla(0, 100%, 100%,0.1)
        padding-right 30px
    
    body .navbar-fixed-top 
        top 0
        border-width 0 0 1px
    
    body .navbar-collapse.collapse 
        display none!important
    
    body .navbar-nav 
        float right
        margin-top 7.5px
    
    body .navbar-nav>li 
        float none
        display block
        clear both
    
    body .navbar-nav>li>a 
        padding-top 10px
        padding-bottom 10px
    
    body .collapse.in
        display block !important
    

    header .navbar-right li:first-child a 
        padding-left 15px
    

    body .navbar-default .navbar-collapse, .navbar-collapse.in, .navbar-collapse.in
        margin-right -15px
        padding-right 0
        padding-left 0
        position absolute !important
        background-color #fff
        width 400px
        right 0
        top 150px
        .navbar-nav
            margin-top 0
            width 100%
            box-shadow -8px 4px 8px rgba(#000, .3)
            li 
                a
                    margin 5px 0
                ul
                    margin 0
                    width 100%
                    li
                        text-align left
    
                    
    

    // .large .navbar-collapse, .large .navbar-collapse.in, .large .navbar-collapse.ing, header .navbar-collapse, header .navbar-collapse.in, header .navbar-collapse.ing 
    //     top 97px

    

    // .small .navbar-collapse, .small .navbar-collapse.in, .small .navbar-collapse.ing 
    //     top 91px
    

    header .navbar-default .navbar-nav>li>a:link, header .navbar-default .navbar-nav>li>a:visited 
        padding-top 15px
        padding-bottom 15px
        padding-left 10px
        padding-right 10px
    

    body .navbar-default .navbar-toggle 
        margin 50px 20px 0 0
    

/*words*/
@media (max-width: 1199px) 
    body
        .container
            padding-left 15px
            padding-right 15px
            .navbar-brand
                padding-left 15px
        header
            .pull-right
                float left !important
                &.click-call
                    float right!important
            .search-container
                .search-input
                    margin-bottom 15px
                    float left
                .search-submit-btn
                    float left
                    clear none
            .navbar-nav
                clear both;
            .navbar-default
                li:first-child a
                    padding-left 20px
                ul.dropdown-menu
                    position relative
                    border 0
                    margin-top 0
                .navbar-toggle
                    margin-right 0
                    .icon-bar
                        width 28px
                        height 3px
                        margin-top 4px
                        background-color $blue
                        &:first-child
                            margin-top 0
                    border 0 !important
                    background-color transparent
                    clear right
                    &:hover, &:focus
                        .icon-bar
                            background-color $alklean-dark-grey

    
    header.large .navbar-nav>li>.dropdown-menu, header .navbar-nav>li>.dropdown-menu 
        margin-top 0

/*Video*/

#video-bg 
    position fixed
    top 0 right 0 bottom 0 left 0
    overflow hidden

#video-bg > video 
    position absolute
    top 0
    left 0
    width 100%
    height 100%

/* 1. No object-fit support */
@media (min-aspect-ratio 16/9) 
    #video-bg > video  
        height 300% 
        top -100%

@media (max-aspect-ratio 16/9)
    #video-bg > video  
        width 300% 
        left -100%
    
/* 2. If supporting object-fit, overriding (1) */
@supports (object-fit cover)
    #video-bg > video
        top 0
        left 0
        width 100% 
        height 100%
        object-fit cover

+below(767px)
    body .navbar-default .navbar-collapse, .navbar-collapse.in, .navbar-collapse.in
        margin-right -15px
        padding-right 0
        padding-left 0
        position absolute !important
        background-color #fff
        width 400px
        right 0
        top 114px
        box-shadow -8px 4px 8px rgba(#000, .3)
        .navbar-nav
            margin-top 0
            width 100%
            margin 0
            li 
                a
                    margin 5px 0
                ul
                    margin 0
                    width 100%
                    li
                        text-align left
                
        +below(550px)
            margin 0
            margin-left -15px
            left 0
            top 110px
            width calc(100% + 30px)
            

+below(767px)
    .sub-header-wrapper 
        h1
            font-size 28px
    header
        .click-call
            top 5px
    
+below(480px)
    .shop-page section.main,
    .products-page section.main 
        margin-top 114px